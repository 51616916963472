import React, { useState } from 'react'
import styled from 'styled-components'
import { Heading } from '../typography/headings'
import { lightenBackground } from '../../utils'
import BackgroundImage from '../backgroundImage'
import Link from '../links/link'

import { ReactComponent as HeadphonesIcon } from '../../images/headphones.svg'
import { ReactComponent as EmailIcon } from '../../images/at.svg'
import { ReactComponent as ChatIcon } from '../../images/chat.svg'
import { ReactComponent as CalendarIcon } from '../../images/calendar-check.svg'
import { ReactComponent as LoginIcon } from '../../images/lock-large.svg'
import { ReactComponent as CloseIcon } from '../../images/close.svg'

const Container = styled.div(
  ({
    theme: { breakpoints, width },
    withImage,
    withContactRequest,
    noMarginTop,
  }) => `
  display: flex;
  flex-direction: ${withContactRequest ? 'row' : 'column'};
  width: 100%;
  max-width: 1074px;
  border-radius: 30px;
  margin: ${
    withImage
      ? '40px auto 73px auto'
      : withContactRequest
      ? '20px auto'
      : noMarginTop
      ? '0 auto 50px auto'
      : '50px auto'
  };
  align-items: center;
  padding: 0 20px;

  @media (max-width: ${breakpoints.md.max}) {
    margin-top: 30px;
  }
`
)

const Title = styled(Heading)(
  ({ theme: { breakpoints } }) => `
  font-family: Saol Display;
  font-size: 45px;
  font-weight: 600;
  line-height: 50px;
  margin-bottom: 50px;

  @media (max-width: ${breakpoints.md.max}) {
    margin-bottom: 30px;
    font-size: 28px;
    line-height: 33px;
  }
`
)

const Content = styled.div(
  ({ theme: { breakpoints } }) => `
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  justify-content: center;

  @media (max-width: ${breakpoints.lg.max}) {
    padding: 0;
  }

  h3 {
    font-size: 38px;
    line-height: 42px;
    margin: 0;
  }
  
  p {
    font-size: 20px;
    line-height: 28px;
    margin: 0;
  }

  a {
    display: flex;
    width: 165px;
    height: 45px;
    border-width: 2px;
    background: transparent;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
`
)

const Row = styled.div(
  ({ theme: { breakpoints } }) => `
  display: flex;
  gap: 20px;

  @media (max-width: ${breakpoints.md.max}) {
    flex-direction: column-reverse;
  }
`
)

const ContactBox = styled.div(
  ({ theme: { breakpoints } }) => `
  display: flex;
  flex-direction: column;
  border-radius: 30px;

  h3 {
    font-size: 38px;
    line-height: 42px; 

    @media (max-width: ${breakpoints.md.max}) {
      font-size: 28px;
      line-height: 33px;
    }
  }
  
  p {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }
`
)

const SmallBox = styled(ContactBox)(
  ({ theme: { breakpoints, colors } }) => `
  position: relative;
  padding: 67px 42px 28px 30px;
  width: 33.3%;

  @media (max-width: ${breakpoints.md.max}) {
    padding: 81px 82px 41px 29px;
    width: 100%;
  }

  h3 {
    max-width: 203px;

    @media (max-width: ${breakpoints.md.max}) {
      max-width: 100%;
      font-size: 28px;
      line-height: 33px;
    }
  }

  p {
    max-width: 238px;

    @media (max-width: ${breakpoints.md.max}) {
      max-width: 100%;
    }
  }
`
)

const Asiakaspalvelu = styled(ContactBox)(
  ({ theme: { breakpoints, colors } }) => `
  flex-direction: row;
  padding: 26px 27px 26px 45px;
  background-color: ${colors.pinkLight};
  gap: 49px;
  width: 67.7%;

  @media (max-width: ${breakpoints.md.max}) {
    flex-direction: column;
    padding: 51px 26px 43px 24px;
    gap: 9px;
    width: 100%;
  }
`
)

const TextContent = styled.div(
  ({ theme: { breakpoints }, small }) => `
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: auto;

  h3, p {
    max-width: ${small ? '203px' : '359px'};

    @media (max-width: ${breakpoints.md.max}) {
      max-width: 100%;
    }
  }
`
)

const ContactContent = styled.div(
  ({ theme: { breakpoints } }) => `
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: ${breakpoints.md.max}) {
    flex-direction: row;
    gap: 10px;
    margin-top: 20px;
  }

  @media (max-width: 430px) {
    flex-direction: column;
  }
`
)

const Chat = styled(SmallBox)(
  ({ theme: { breakpoints, colors } }) => `
  background-color: ${lightenBackground(colors.orange, 0.6)};
`
)

const Booking = styled(SmallBox)(
  ({ theme: { breakpoints, colors } }) => `
  background-color: ${colors.red};
`
)

const ContactRequest = styled(SmallBox)(
  ({ theme: { breakpoints, colors } }) => `
  background-color: ${colors.primaryColor};
  color: ${colors.white};

  h3 {
    color: ${colors.white};
  }
`
)

const Login = styled(SmallBox)(
  ({ theme: { breakpoints, colors } }) => `
  background-color: ${colors.blue};
  color: ${colors.white};

  h3 {
    color: ${colors.white};
  }
`
)

const ImageWrap = styled(SmallBox)(
  ({ theme: { breakpoints }, article }) => `
  object-fit: cover;
  border-radius: 30px;
  overflow: hidden;
  min-height: 320px;
  ${article ? 'padding: 0;' : ''}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: 0;
  }

  @media (max-width: ${breakpoints.md.max}) {
    ${article ? 'display: none;' : ''}
  }
`
)

const Box = styled.div(
  ({ theme: { breakpoints, colors } }) => `
  display: flex;
  flex-direction: column;
  width: 160px;
  height: 124px;
  background-color: ${lightenBackground(colors.pinkLight, 0.5)};
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  @media (max-width: ${breakpoints.md.max}) {
    width: 100%;
  }

  a {
    color: ${colors.primaryColor};
    height: 32px;
    font-weight: 500;
    line-height: 20px;

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    line-height: 19px;
    letter-spacing: -0.64px;
  }
`
)

const PhoneBox = styled(Box)``

const EmailBox = styled(Box)``

const ChatBox = styled.div(
  ({ theme: { colors } }) => `
  position: absolute;
  display: flex;
  width: 90px;
  height: 90px;
  background-color: ${lightenBackground(colors.orange, 0.5)};
  border-radius: 50%;
  top: 14px;
  right: 13px;
  align-items: center;
  justify-content: center;

  svg {
    path {
      fill: ${lightenBackground(colors.orange, 0.2)};
    }
  }
`
)

const CalendarBox = styled(ChatBox)(
  ({ theme: { colors } }) => `
  background-color: ${lightenBackground(colors.red, 0.4)};

  svg {
    path {
      fill: ${colors.red};
    }
  }
`
)

const CalendarAltBox = styled(ChatBox)(
  ({ theme: { colors } }) => `
  background-color: ${lightenBackground(colors.primaryColor, 0.3)};

  svg {
    path {
      fill: ${colors.primaryColor};
    }
  }
`
)

const LoginBox = styled(ChatBox)(
  ({ theme: { colors } }) => `
  background-color: ${lightenBackground(colors.blue, 0.2)};

  svg {
    path {
      fill: ${colors.white};
    }
  }
`
)

const Cta = ({ theme: { colors }, altTheme }) => `
  display: flex;
  height: 45px;
  width: 180px;
  align-items: center;
  justify-content: center;
  border: ${
    altTheme ? `2px solid ${colors.red}` : `2px solid ${colors.primaryColor}`
  };
  border-radius: 30px;
  color: ${altTheme ? colors.white : colors.primaryColor};

  font-size: 16px;
  font-weight: 500;
  line-height: 20px;

  &:hover {
    background-color: ${altTheme ? colors.red : colors.primaryColor};
    color: ${altTheme ? colors.primaryColor : colors.white};
  }
`

const CtaButton = styled.button`
  ${Cta}
`

const CtaLinkButton = styled(Link)(
  ({ theme: { colors }, altTheme }) => `
  ${Cta({ theme: { colors }, altTheme })}
  color: ${props => props.theme.colors.primaryColor};
`
)

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 4, 80, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

const CloseButton = styled.button(
  ({ theme: t }) => `
  position: absolute;
  top: 22px;
  right: 22px;
  background-color: transparent;
  color: ${t.colors.primaryColor};
  border: none;
  z-index: 1001;
`
)

const CalendarContainerDesktop = styled.div(
  ({ theme: t }) => `
  display: flex;
  position: relative;
  flex-direction: column;
  width: 660px;
  height: 610px;
  border-radius: 30px;
  background-color: ${t.colors.white};
  color: ${t.colors.primaryColor};
  align-items: center;
  justify-content: center;
  padding: 0;
  overflow: hidden;

  @media (max-width: 660px) {
    width: 100%;
    margin: 20px;
  }
`
)

const ContactMethods = ({
  short,
  withImage,
  withContactRequest,
  large,
  noMarginTop,
  title,
  image,
}) => {
  const [showCalendar, setShowCalendar] = useState(false)

  const openLeadooChat = () => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = 'https://bot.leadoo.com/bot/dynamic.js?company=t6UelyAe'
    document.body.appendChild(script)
  }

  return (
    <Container
      withImage={withImage}
      withContactRequest={withContactRequest}
      noMarginTop={noMarginTop}
    >
      <Title as="h2">{title}</Title>
      <Content>
        <Row>
          {!withImage && !withContactRequest && (
            <Asiakaspalvelu>
              <TextContent>
                <h3>Ota yhteyttä asiakaspalveluun</h3>
                <p>
                  Asiakaspalvelumme palvelee 8.30-16.30 (ma-pe). Kehuttu
                  asiakaspalvelumme vastaa kaikkiin sinua mietityttäviin
                  kysymyksiin.
                </p>
              </TextContent>
              <ContactContent>
                <PhoneBox>
                  <HeadphonesIcon />
                  <a
                    target="__blank"
                    href={`tel:${'0 200 10 100'.replace(/\s/g, '')}`}
                  >
                    0 200 10 100
                  </a>
                  <span>(pvm/mpm)</span>
                </PhoneBox>
                <EmailBox>
                  <EmailIcon />
                  <a target="__blank" href={'mailto:info@alexandria.fi'}>
                    info@alexandria.fi
                  </a>
                </EmailBox>
              </ContactContent>
            </Asiakaspalvelu>
          )}
          {(short || withImage || withContactRequest) && (
            <Chat>
              <TextContent small={true}>
                <h3>Kysy chatissa</h3>
                <p>Chat -asiakaspalvelumme palvelee 8 - 16.00 (ma-pe)</p>
                <CtaButton onClick={openLeadooChat}>Avaa chat</CtaButton>
              </TextContent>
              <ChatBox>
                <ChatIcon />
              </ChatBox>
            </Chat>
          )}
          {withContactRequest && (
            <ContactRequest>
              <TextContent small={true}>
                <h3>Jätä yhteyden-ottopyyntö</h3>
                <p>Otamme yhteyttä 24h sisällä haluamallasi tavalla.</p>
                <CtaLinkButton
                  altTheme={true}
                  to={'/toimipaikat-ja-yhteystiedot'}
                >
                  Jätä yhteystiedot
                </CtaLinkButton>
              </TextContent>
              <CalendarAltBox>
                <CalendarIcon />
              </CalendarAltBox>
            </ContactRequest>
          )}
          {withImage && (
            <Booking>
              <TextContent small={true}>
                <h3>Varaa aika</h3>
                <p>
                  Varaa aika henkilökohtaiseen tapaamiseen. Palvelemme 30
                  paikkakunnalla ympäri maan.
                </p>
                <CtaButton onClick={() => setShowCalendar(true)}>
                  Varaa aika
                </CtaButton>
              </TextContent>
              <CalendarBox>
                <CalendarIcon />
              </CalendarBox>
            </Booking>
          )}
          {!short && image && (
            <ImageWrap article={!image.gatsbyImageData}>
              {image.gatsbyImageData ? (
                <BackgroundImage image={image.gatsbyImageData} alt={''} />
              ) : (
                <img src={image} alt={''} />
              )}
            </ImageWrap>
          )}
        </Row>
        {large && (
          <Row>
            <Chat>
              <TextContent small={true}>
                <h3>Kysy chatissa</h3>
                <p>Chat -asiakaspalvelumme palvelee 8 - 16.00 (ma-pe)</p>
                <CtaButton onClick={openLeadooChat}>Avaa chat</CtaButton>
              </TextContent>
              <ChatBox>
                <ChatIcon />
              </ChatBox>
            </Chat>
            <Booking>
              <TextContent small={true}>
                <h3>Varaa aika</h3>
                <p>
                  Varaa aika henkilökohtaiseen tapaamiseen. Palvelemme 30
                  paikkakunnalla ympäri maan.
                </p>
                <CtaButton onClick={() => setShowCalendar(true)}>
                  Varaa aika
                </CtaButton>
              </TextContent>
              <CalendarBox>
                <CalendarIcon />
              </CalendarBox>
            </Booking>
            <Login>
              <TextContent small={true}>
                <h3>Oletko jo asiakas?</h3>
                <p>
                  Verkkopalvelustamme näet tarkat tiedot sijoitustesi
                  kehityksestä.
                </p>
                <CtaButton altTheme={true}>Kirjaudu</CtaButton>
              </TextContent>
              <LoginBox>
                <LoginIcon />
              </LoginBox>
            </Login>
          </Row>
        )}
      </Content>
      {showCalendar && (
        <Modal>
          <CalendarContainerDesktop>
            <CloseButton onClick={() => setShowCalendar(false)}>
              <CloseIcon />
            </CloseButton>
            <iframe
              src="https://oma.alexandria.fi/ajanvaraus"
              name="calendar"
              frameborder="0"
              height="800px"
              width="100%"
            ></iframe>
          </CalendarContainerDesktop>
        </Modal>
      )}
    </Container>
  )
}

export default ContactMethods
